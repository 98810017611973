<template>
  <div class="SDE">
    <div class="container">
      <img src="@/assets/img/course/Class3/banner1.png" alt="" />
      <!-- <PolyVideo :class-id="3"></PolyVideo> -->
      <img src="@/assets/img/course/Class3/banner2.png" alt="" />
      <img src="@/assets/img/course/Class3/banner3.png" alt="" />
      <img src="@/assets/img/course/Class3/banner4.png" alt="" />
      <img src="@/assets/img/course/Class3/banner5.png" alt="" />
      <img src="@/assets/img/course/Class3/banner6.png" alt="" />
      <img src="@/assets/img/course/Class3/banner7.png" alt="" />
      <img src="@/assets/img/course/Class3/banner8.png" alt="" />
      <img src="@/assets/img/course/Class3/banner9.png" alt="" />
      <img src="@/assets/img/course/Class3/banner10.png" alt="" />
      <img src="@/assets/img/course/Class3/banner11.png" alt="" />
      <img src="@/assets/img/course/Class3/banner12.png" alt="" />
      <img src="@/assets/img/course/Class3/banner13.png" alt="" />
      <img src="@/assets/img/course/Class3/banner14.png" alt="" />
      <img src="@/assets/img/course/Class3/banner15.png" alt="" />
    </div>
    <div class="prices">
      <span class="usdPrice">${{ courseCombosList[0].usdprice }} /</span>
      <span class="rmbPrice">￥{{ courseCombosList[0].price }}</span>
      <div class="buy" @click="buyNow(courseCombosList[0])">立即购买</div>
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
import { getCourseDetail } from "@/service/course";
import PolyVideo from "@/components/PolyVideo";
import { setStorageForArray } from "@/utils/index";
export default {
  name: "Class3",
  components: { PolyVideo },
  data() {
    return {
      courseCombosList: [],
    };
  },
  mounted() {
    getCourseDetail(3).then((res) => {
      this.courseCombosList = res.result.courseCombosList;
    });
  },
  methods: {
    buyNow(item) {
      setStorageForArray("order", [item]);
      this.$router.push("/pay/confirm");
    },
  },
};
</script>
<style scoped lang="scss">
@import url("../../assets/css/course.scss");
.SDE {
  width: 100%;
  .container {
    img {
      width: 100%;
      vertical-align: top;
    }
  }
}
</style>